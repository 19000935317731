<template>
  <div class="content-container">
    <PageTitle :title="$t('PRIZES.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('EMPTY_TABLE.PRIZES.TITLE') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')"
                :route-name="ROUTE_NAMES_CMS.PRIZES"
                :pre-leave-check="allowLeaving"/>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData"
                 class="page-form"
                 :on-form-data-update="updatedForm">
      <template #form-content>

        <!-- question -->
        <InputField :field-title="$t('CREATE_MISSION.TITLE')"
                    :field-name="'prizeTitle'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('CREATE_PRIZE.TITLE_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    class="form-element"/>

        <!-- subtitle -->
        <InputField id="subtitle"
                    :type="'text'"
                    :field-title="$t('CREATE_PRIZE.SUBTITLE')"
                    :placeholder="$t('CREATE_PRIZE.SUBTITLE_PLACEHOLDER')"
                    :field-name="'subtitle'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :inline-subtitle="`(${$t('OPTIONAL')})`"
                    :rules="'optional'"
                    class="form-element"/>

        <!-- description -->
        <div class="description-section">
          <RichTextField id="description"
                         :field-title="$t('DIAS.DESCRIPTION')"
                         :input-callback="instructionInput"
                         :placeholder="$t('DIAS.DESCRIPTION_PLACEHOLDER')"
                         :field-name="'prizeDescription'"
                         :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                         :callback="() => {}"
                         class="form-element"/>

        </div>
        <!-- partenrlink -->
        <InputField id="partnerLink"
                    :field-title="$t('EXTRA_GAMES.PARTNER_LINK')"
                    :dropdown-options="status"
                    :placeholder="$t('EXTRA_GAMES.EXAMPLE_LINK')"
                    :field-name="'partner'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :callback="() => {}"
                    :inline-subtitle="`(${$t('OPTIONAL')})`"
                    :rules="'optional'"
                    class="form-element"/>

        <!-- Add a section for adding the photo -->
        <div class="cover-photo-section">
          <h3>{{ $t('CREATE_PRIZE.COVER_PHOTO') }}</h3>
          <BasicButton
            v-if="!imagePreview"
            id="uploadPhoto"
            :button-text="$t('DIAS.FILE_CHOOSE')"
            :button-class="'base-button'"
            @click="createPrizeImage"
          />

          <FilePreview
            v-if="imagePreview"
            id="coverPhoto-Image"
            :file="imagePreview"
            :remove-button-clicked="removeAttachment"
            :style-preset="'big'"
          />
        </div>

      </template>
      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('SAVE') }}
        </button>
      </template>
    </FormWrapper>

    <BasicModal v-if="isBasicVersionDisplayed"
                :button-right-disabled="!fileData">
      <template #content>
        <file-dropbox
          :allowed-mime-types="allowedMimes"
          :style-preset="'small'"
          :callback="uploadFile"
        />
      </template>
    </BasicModal>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import FileDropbox from '../../../components/elements/FileDropbox.vue'
import BasicButton from '../../../components/elements/basic/BasicButton.vue'
import FilePreview from '@/components/elements/FilePreview'

export default {
  name: 'CreatePrizes',
  components: {
    BackButton,
    PageTitle,
    InputField,
    FormWrapper,
    BasicModal,
    RichTextField,
    FilePreview,
    BasicButton,
    FileDropbox
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()
    const imagePreview = ref(null)
    const fileData = ref(null)
    const currentFormData = ref({})
    const allowedMimes = computed(() => ['image/png', 'image/jpeg', 'image/webp'])
    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.CREATE_ATTACHMENT
    )

    function imageLoad() {
      if (fileData.value) {
        imagePreview.value = fileData.value
      }
    }

    /** Sign Language **/ // TODO: Add translation string hre.

    /** Form **/
    function validateData(data) {
      if (!data.content || data.content === '') {
        return false
      }
      else
      {
        createPrize(data)
      }
    }

    /** Create Class **/
    function createPrize(data) { /*
        store.dispatch(CREATE_PRIZE, data).then(response => {
        if (response) {
          router.push({ name: ROUTE_NAMES_CMS.PRIZES })
        }
      })*/
      console.log('fake create. API REQUIRED', data)
    }

    function createPrizeImage() {
      modalController.value.setModal(
        BASIC_MODAL_CONTENT.UPLOAD_FILE,
        uploadAttachment,
        null,
        null,
        MODAL_NAMES.CREATE_PRIZE_IMAGE)
    }

    function uploadAttachment() {
      imageLoad()
      modalController.value.resetActiveModal()
    }

    function removeAttachment() {
      fileData.value = null
      imagePreview.value = null
    }

    function uploadFile(data) {
      fileData.value = data[0]
    }

    function isEmpty(value) {
      return !value || value === ''
    }

    function allowLeaving() {
      let empty = true
      for (var key in currentFormData.value) {
        empty &= isEmpty(currentFormData.value[key])
      }
      if (empty) {
        return true
      }
      modalController.value.setModal(BASIC_MODAL_CONTENT.CANCEL_EARLY_PRIZE, leave, null, null, MODAL_NAMES.LEAVE_EARLY_CREATE_PRIZE)

      //show confirm leaving popup
      return false
    }

    function leave() {
      modalController.value.resetActiveModal()
      router.push({ name: ROUTE_NAMES_CMS.PRIZES })
    }

    function updatedForm(data) {
      currentFormData.value = data
    }

    return {
      ROUTE_NAMES_CMS,

      /** Group Dropdown **/
      status,

      allowLeaving,
      updatedForm,

      isBasicVersionDisplayed,

      /** Form **/
      validateData,
      allowedMimes,
      fileData,
      imagePreview,
      createPrizeImage,
      removeAttachment,
      imageLoad,
      uploadFile
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.cover-photo-section {
    button{
      background-color: white;
    }
    .file-image-preview.big {
        width: rem(350);
        height: rem(240);
    }

    h3{
      color: var(--blue_dark);
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.1875rem;
      margin-bottom: 0.5rem;
      text-align: left;
    }
}
.description-section {
  margin-bottom: rem(24);
}



</style>
